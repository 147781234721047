import React from "react";
import Card from "../components/Card";
import { setGlobal } from "reactn";
import Breadcrumb from "../components/Breadcrumb";
import { FormsCrumbs } from "../utils/BreadcrumbData";
import Loading from "../components/Loading";
import APIget from "../helpers/APIget";
import { NavLink } from "react-router-dom";

import {
  VscFiles,
} from "react-icons/vsc";

export default function ViewForms() {
  const GetData = () => {
    const { isLoaded, data } = APIget("/view-forms/");

    if (!isLoaded) {
      return (
        <>
          <Loading />
        </>
      );
    }
    if (isLoaded) {
      setGlobal({ csrfToken: data.newtoken });

      const forms = data.forms;

      return (
        <>
          {forms !== undefined ? (
            <>
              {Object.keys(forms).map((form) => (
                <NavLink key={forms[form].id} exact to={"/form-data/" + forms[form].id + "/"}>
                  <Card
                    key={forms[form].id}
                    title={forms[form].form_name}
                    icon={<VscFiles />}
                  ></Card>
                </NavLink>
              ))}
            </>
          ) : (
            <>
              <Card alert="info">
                <p>No Forms Found</p>
              </Card>
            </>
          )}
        </>
      );
    }
  };
  return (
    <>
      <Breadcrumb items={FormsCrumbs} />
      <GetData />
    </>
  );
}
